import React, { useContext } from "react";
import {
  FieldInput,
  FieldSelect,
  FieldLabel,
  FormControl,
  FieldError,
} from "../Input";
import styles from "./BooleanBuilder.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import ColorPicker from "./ColorPicker";

const BooleanBuilder = ({ property }) => {
  const { t } = useContext(I18nContext);

  return (
    <>
      <div className={styles.row}>
        <FormControl>
          <FieldLabel>{t("true label")}</FieldLabel>
          <FieldInput size="m" name="trueLabel" />
          <FieldError name="trueLabel" />
        </FormControl>
        <div className={styles.indicator}> </div>
        <FormControl>
          <FieldLabel>{t("true color")}</FieldLabel>
          <ColorPicker name="trueColor" />
          <FieldError name="trueColor" />
        </FormControl>
      </div>
      <div className={styles.row}>
        <FormControl>
          <FieldLabel>{t("false label")}</FieldLabel>
          <FieldInput size="m" name="falseLabel" />
          <FieldError name="falseLabel" />
        </FormControl>
        <div className={styles.indicator}> </div>
        <FormControl>
          <FieldLabel>{t("false color")}</FieldLabel>
          <ColorPicker name="falseColor" />
          <FieldError name="falseColor" />
        </FormControl>
      </div>
    </>
  );
};

export default BooleanBuilder;
