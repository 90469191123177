import React, { useContext } from "react";
import Select, { components } from "react-select";
import PropTypes from "prop-types";
import Icon from "../Icon";
import { I18nContext } from "../../i18n/I18nContext";

const focusColor = "#EE7203";

const ValueContainer = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && (
          <Icon name="loupe" style={{ position: "absolute", left: 0 }} />
        )}
        {children}
      </components.ValueContainer>
    )
  );
};

const MultipleValue = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && (
          <Icon name="loupe" style={{ position: "absolute", left: 0 }} />
        )}
        {children}
      </components.ValueContainer>
    )
  );
};

const Filters = ({
  withDefault,
  options,
  onSelect,
  placeholder,
  value,
  icon,
  isMultiple,
  isClearable,
  customLabel,
  wide,
  medium,
}) => {
  const { t } = useContext(I18nContext);
  const customStylesIcon = {
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontWeight: "400",
        marginLeft: "10px",
        color: "#393939",
        fontSize: "14px",
      },
    }),
    singleValue: (provided) => {
      return {
        ...provided,
        marginLeft: "12px",
      };
    },
    option: (styles, { data }) => {
      return {
        ...styles,
        display: "flex",
        alignItems: "center",
        color: "#393939",
        height: "auto",
        backgroundColor: data.global ? "#fff8f1" : "#ffffff",
        "&:hover": {
          backgroundColor: "#FFF8F1",
          color: "#393939",
          cursor: "pointer",
        },
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        border: `1px solid ${focusColor}`,
      };
    },
    container: (provided) => {
      return {
        ...provided,
        height: "auto",
        width: "390px",
        color: "#393939",
        borderRadius: "6px",
        fontSize: "14px",
        lineHeight: "16px",
        "&:hover": {
          backgroundColor: "#EE7203",
        },
      };
    },
    control: (base, state, provided) => ({
      ...provided,
      paddingLeft: "8px",
      fontWeight: "400",
      fontSize: "16px",
      minHeight: "36px",
      height: "auto",
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: state.isFocused ? focusColor : base.borderColor,
      "&:hover": {
        borderColor: state.isFocused ? focusColor : base.borderColor,
        cursor: "pointer",
      },
    }),
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        backgroundColor: "none",
      };
    },
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      "& svg": { display: "none" },
    }),
    placeholder: (provided) => {
      return {
        ...provided,
        marginLeft: "20px",
        color: "#AAAAAA",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "16px",
      };
    },
  };

  const customStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        display: "flex",
        alignItems: "center",
        color: "#393939",
        height: "auto",
        backgroundColor: data.global ? "#fff8f1" : "#ffffff",
        "&:hover": {
          backgroundColor: "#eaeaea",
          color: "#393939",
          cursor: "pointer",
        },
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        border: `1px solid ${focusColor}`,
      };
    },
    container: (provided, state) => {
      return {
        ...provided,
        width: wide ? "400px" : medium ? "300px" : "230px",
        color: "#393939",
        borderRadius: "6px",
        marginRight: "12px",
        fontSize: "14px",
        lineHeight: "16px",
      };
    },
    control: (base, state, provided) => ({
      ...provided,
      paddingLeft: "8px",
      fontWeight: "400",
      fontSize: "16px",
      minHeight: "36px",
      height: "auto",
      dropdownIndicator: "red",
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: state.isFocused ? focusColor : base.borderColor,
      "&:hover": {
        borderColor: state.isFocused ? focusColor : base.borderColor,
        cursor: "pointer",
      },
    }),
    multiValue: (base, state, provided) => ({
      ...base,
      maxWidth: "100%",
      height: "auto",
      cursor: "pointer",
      textOverflow: "wrap",
      overflow: "visible",
      whiteSpace: "wrap",
    }),
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        backgroundColor: "none",
      };
    },
    dropdownIndicator: (base, state) => {
      return {
        ...base,
        color: state.isFocused ? "#EE7203" : "#AAAAAA",
      };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        marginLeft: "2px",
        color: "#AAAAAA",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "16px",
      };
    },
  };

  const multiLabelStyles = {
    position: "relative",
    width: "100%",
    fontSize: "13px",
    padding: "1px 3px",
  };

  const MultiValueLabel = ({ selectProps, data }) => {
    const label = selectProps.getOptionLabel(data);
    return <div style={multiLabelStyles}>{label}</div>;
  };

  return (
    <>
      {icon ? (
        <Select
          value={value}
          isClearable={isClearable !== false}
          styles={customStylesIcon}
          isSearchable={true}
          isMulti={isMultiple}
          defaultValue={withDefault ? options[0] : null}
          options={options}
          onChange={onSelect}
          placeholder={placeholder || t("select")}
          noOptionsMessage={() => t("no options")}
          components={{ ValueContainer }}
          formatOptionLabel={customLabel ? customLabel : null}
          closeMenuOnSelect={!isMultiple}
        />
      ) : (
        <Select
          value={value}
          isClearable={isClearable !== false}
          styles={customStyles}
          isSearchable={true}
          isMulti={isMultiple}
          options={options}
          defaultValue={withDefault ? options[0] : null}
          onChange={onSelect}
          placeholder={placeholder || t("select")}
          noOptionsMessage={() => t("no options")}
          formatOptionLabel={customLabel ? customLabel : null}
          closeMenuOnSelect={!isMultiple}
          components={isMultiple ? { MultiValueLabel } : {}}
        />
      )}
    </>
  );
};

Filters.propTypes = {
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  icon: PropTypes.bool,
};

export default Filters;
