import React, { useContext } from "react";
import { generatePath } from "react-router-dom";
import styles from "./RecurrenceTasksItem.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import Icon from "../Icon";
import history from "../../history";

const RecurrenceTasksItem = ({
  taskPool,
  onEditClick,
  onDeleteClick,
  assetDetailsId,
}) => {
  const { t } = useContext(I18nContext);
  const today = new Date();

  const taskActionDelayUnitsOptions = [
    { value: "DAYS", label: t("days") },
    { value: "WEEKS", label: t("weeks") },
    { value: "MONTHS", label: t("months") },
    { value: "QUARTER_YEAR", label: t("quarter year") },
    { value: "HALF_YEAR", label: t("half year") },
    { value: "YEARS", label: t("years") },
  ];

  const handleOpenDetails = (assetId) => {
    history.push(generatePath("/assets/details/:assetId", { assetId }));
  };
  const path = taskPool.assetParentPath;
  if (path[path.length - 1] === taskPool.assetName) {
    path.pop();
  }
  return (
    <div className={styles.task_item} key={taskPool.id}>
      <div className={styles.task_info}>
        <div className={styles.task_info_item}>
          {taskPool.taskPrototype.name}
          {!!taskPool.scheduler &&
            taskPool.scheduler.intervalUnit !== "NONE" && (
              <span className={styles.recurrence}>
                {"("}
                {t("recurs every")}: {taskPool.scheduler.recurringInterval}{" "}
                {
                  taskActionDelayUnitsOptions.find(
                    (x) => x.value === taskPool.scheduler.intervalUnit
                  )?.label
                }
                {")"}
              </span>
            )}
          <div className={styles.description_tooltip}>
            {taskPool.taskPrototype.desc}
          </div>
        </div>
        <div className={styles.task_info_item}>
          {!!taskPool.assetParentPath && !!taskPool.assetParentPath.length && (
            <span className={styles.path}>
              {path.length !== 1 ? path.join(" / ") + " / " : ""}
              <span
                className={styles.asset_name}
                onClick={() => handleOpenDetails(taskPool.assetId)}
              >
                {taskPool.assetName ? taskPool.assetName : path}
              </span>
            </span>
          )}
          {!!taskPool.assetParentPath && !taskPool.assetParentPath.length && (
            <span className={styles.path}>
              <span
                className={styles.asset_name}
                onClick={() => handleOpenDetails(taskPool.assetId)}
              >
                {taskPool.assetName}
              </span>
            </span>
          )}
        </div>
        <div className={styles.task_info_item_small_margin_bottom}>
          <div className={styles.task_info_item_label}>
            {new Date(taskPool.scheduler.startDate) > today &&
            !taskPool.finished
              ? t("starts")
              : t("started")}
            :
          </div>
          <div className={styles.task_info_item_value}>
            {new Date(taskPool.scheduler.startDate).toLocaleDateString(
              undefined,
              {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }
            )}{" "}
            /
          </div>
          <div className={styles.task_info_item_label}>
            {!taskPool.finished ? t("ends") : t("ended")}:
          </div>
          <div className={styles.task_info_item_value}>
            {taskPool.scheduler.finishRecurrenceAt
              ? new Date(
                  taskPool.scheduler.finishRecurrenceAt
                ).toLocaleDateString(undefined, {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              : "-"}{" "}
            /
          </div>
          <div className={styles.task_info_item_label}>
            {t("last task closed")}:
          </div>
          <div className={styles.task_info_item_value}>
            {taskPool.finishedTasks && taskPool.finishedTasks.length
              ? new Date(
                  taskPool.finishedTasks?.at(-1).dueDate
                ).toLocaleDateString(undefined, {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              : "-"}
            {" / "}
          </div>
          <div className={styles.task_info_item_label}>
            {t("current task deadline")}:
          </div>
          <div className={styles.task_info_item_value}>
            {taskPool.activeTasks.length
              ? new Date(taskPool.activeTasks[0]?.dueDate).toLocaleDateString(
                  undefined,
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }
                )
              : "-"}
          </div>
        </div>

        {!!taskPool.createdBy && (
          <div className={styles.created}>
            {t("created by")}
            {": "}
            {taskPool.createdBy}
          </div>
        )}
        {!!taskPool.ruleName && (
          <span className={styles.created}>
            {t("task created by rule") + ": " + taskPool.ruleName}
          </span>
        )}
        {!!taskPool.ruleName && (
          <span className={styles.created}>
            {t("task created by rule") + ": " + taskPool.ruleName}
          </span>
        )}
        {taskPool?.activeTasks
          .map((task) => task.assetId)
          .includes(assetDetailsId) && (
          <div className={styles.has_task_info}>{t("has open task")}</div>
        )}
      </div>
      <div className={styles.task_actions}>
        <div className={styles.action_buttons}>
          {!taskPool.finished && (
            <div
              className={styles.action_button}
              onClick={(e) => onEditClick(e, taskPool)}
            >
              <Icon name="edit" className={styles.task_edit} />
            </div>
          )}
          <div
            className={styles.action_button}
            onClick={(e) => onDeleteClick(e, taskPool)}
          >
            <Icon name="trash" className={styles.task_delete} height={"50px"} />
          </div>
        </div>
        {!taskPool.finished && taskPool.status === "suspended" && (
          <div className={styles.recurrence_suspended}>
            {t("recurrence suspended", "upper")}
          </div>
        )}
        {new Date(taskPool.scheduler.startDate) <= today &&
          !taskPool.finished &&
          taskPool.status !== "suspended" && (
            <div className={styles.recurrence_active}>
              {t("recurrence started", "upper")}
            </div>
          )}
        {new Date(taskPool.scheduler.startDate) > today &&
          !taskPool.finished && (
            <div className={styles.recurrence_pending}>
              {t("recurrence pending", "upper")}
            </div>
          )}
        {!!taskPool.finished && (
          <div className={styles.recurrence_finished}>
            {t("recurrence finished", "upper")}
          </div>
        )}
      </div>
    </div>
  );
};

export default RecurrenceTasksItem;
