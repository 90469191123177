import React, { useContext, useEffect, useState } from "react";
import Dashboard from "../../layouts/Dashboard";
import { FilterDropdown } from "../../components/Dropdown";
import {
  AssetHeaderDetails,
  AssetDetailsContent,
} from "../../components/AssetDetails";
import styles from "./AssetDetails.module.scss";
import Icon from "../../components/Icon";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAsset,
  fetchAssetDetails,
  fetchAssets,
  updateAssetState,
  getAssetStateEvents,
} from "../../redux";
import history from "../../history";
import { appUrls } from "../../urls";
import { prepareStatesOptions, prepareStateEventsOptions } from "./utils";
import { I18nContext } from "../../i18n/I18nContext";
import DeleteAssetModal from "./DeleteAssetModal";
import jwtDecode from "jwt-decode";

const AssetDetails = ({
  id,
  closeModal,
  onDeleteBuilding,
  updateAsset,
  parentsIds = [],
}) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const paramsId = useParams().assetId;
  const assetId = id ? id : paramsId;

  const info = useSelector((state) => state.auth.info);
  const isAdmin = info?.token
    ? jwtDecode(info?.token).role === "SUPER_ADMIN" ||
      jwtDecode(info?.token).role === "CUSTOMER_ADMIN" ||
      jwtDecode(info?.token).role === "ADMIN"
    : false;

  const details = useSelector((state) => state.assets.details);

  const [assetStateEventsOptions, setAssetStateEventsOptions] = useState([]);
  const [assetState, setAssetState] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    getAssetDetails();
  }, [assetId, dispatch]);

  const getAssetDetails = () => {
    if (!!assetId) {
      dispatch(fetchAssetDetails(assetId));
    }
  };

  useEffect(() => {
    if (!!assetId) {
      getAssetStateEventsOptions();
    }
  }, [assetId]);

  useEffect(() => {
    if (details) {
      setAssetState(() =>
        prepareStatesOptions(t, [details.assetState], details.grouping)
      );
      getAssetStateEventsOptions();
    }
  }, [details]);

  const getAssetStateEventsOptions = async () => {
    const availableStateEvents = await getAssetStateEvents(assetId);
    const options = await prepareStateEventsOptions(t, availableStateEvents);
    const topLevelAssetsStateOptions =
      details?.assetState === "ACTIVE"
        ? [{ value: "DEACTIVATE", label: t("deactivate asset") }]
        : details?.assetState === "FOR_CREATION"
        ? [
            { value: "ACTIVATE", label: t("activate asset") },
            { value: "DECLINE_CREATION", label: t("decline creation") },
          ]
        : [{ value: "REACTIVATE", label: t("reactivate") }];
    setAssetStateEventsOptions(
      details?.topLevel ? topLevelAssetsStateOptions : options
    );
  };

  const handleDeleteAsset = async () => {
    if (details) {
      await dispatch(deleteAsset(t, details.id));
      await dispatch(fetchAssets(details.parentId));
      setOpenDeleteModal(false);
      if (!details.parentId) {
        onDeleteBuilding();
      }
      if (!!updateAsset) {
        parentsIds.forEach((parentId) => {
          updateAsset(parentId);
        });
      }
      if (!!closeModal) {
        closeModal();
      } else {
        history.push(appUrls.ASSETS);
      }
    }
  };

  const handleShowDeleteAssetModal = async () => {
    setOpenDeleteModal(true);
  };

  const changeAssetState = async (assetState) => {
    if (assetState) {
      await dispatch(updateAssetState(t, assetId, assetState.value));
      await dispatch(fetchAssets(details.parentId));
    }
  };

  if (!details) {
    return null;
  }

  const path = !details.path
    ? ""
    : !details.path.length
    ? t("top level")
    : details.path.filter((x) => x !== details.name).join(" -> ");

  if (!!closeModal) {
    return (
      <>
        <div className={styles.row}>
          <AssetHeaderDetails
            title={details.name}
            path={path}
            protoName={details?.prototypeName}
            protoDesc={details?.prototypeDesc}
            closeModal={closeModal}
          />
          <div className={styles.right_content}>
            {(!details.grouping || isAdmin) && (
              <span className={styles.title}>{t("asset state")}:</span>
            )}
            {(!details.grouping || isAdmin) && (
              <FilterDropdown
                options={assetStateEventsOptions}
                onSelect={changeAssetState}
                value={assetState}
                isClearable={false}
                isDisabled={details.grouping && !details.topLevel}
              />
            )}
            <span
              className={styles.icon_box}
              onClick={handleShowDeleteAssetModal}
            >
              <Icon name="trash" className={styles.icon} />
            </span>
          </div>
        </div>
        {details && (
          <AssetDetailsContent
            assetDetails={details}
            getAssetDetails={getAssetDetails}
          />
        )}
        {!!openDeleteModal && (
          <DeleteAssetModal
            key="delete-asset"
            asset={details}
            isOpen={openDeleteModal}
            onClose={() => {
              setOpenDeleteModal(false);
            }}
            deleteAssetMethod={handleDeleteAsset}
          />
        )}
      </>
    );
  } else {
    return (
      <Dashboard>
        <div className={styles.row}>
          <AssetHeaderDetails
            title={details.name}
            path={path}
            protoName={details?.prototypeName}
            protoDesc={details?.prototypeDesc}
          />
          <div className={styles.right_content}>
            <span className={styles.title}>{t("asset state")}:</span>
            <FilterDropdown
              options={assetStateEventsOptions}
              onSelect={changeAssetState}
              value={assetState}
              isClearable={false}
            />
            <span className={styles.icon_box}>
              <Icon
                name="trash"
                className={styles.icon}
                onClick={handleShowDeleteAssetModal}
              />
            </span>
          </div>
        </div>
        {details && (
          <AssetDetailsContent
            assetDetails={details}
            getAssetDetails={getAssetDetails}
          />
        )}
        {!!openDeleteModal && (
          <DeleteAssetModal
            key="delete-asset"
            asset={details}
            isOpen={openDeleteModal}
            onClose={() => {
              setOpenDeleteModal(false);
            }}
            deleteAssetMethod={handleDeleteAsset}
          />
        )}
      </Dashboard>
    );
  }
};

export default AssetDetails;
