import Actions from "../actions";
import axios from "../auth";
import { createToast } from "../toast/toastActions";
import { downloadFile } from "../../helpers/helpers";
import { refreshSuccess, refreshToken } from "../auth/authActions";
import { fetchAssets } from "../assets/assetsActions";

const tasksLoadingStart = () => {
  return {
    type: Actions.TASKS_LOADING_START,
  };
};

const tasksLoadingStop = () => {
  return {
    type: Actions.TASKS_LOADING_STOP,
  };
};

const tasksRecentLoadingStart = () => {
  return {
    type: Actions.TASKS_RECENT_LOADING_START,
  };
};

const tasksRecentLoadingStop = () => {
  return {
    type: Actions.TASKS_RECENT_LOADING_STOP,
  };
};

const tasksReadyLoadingStart = () => {
  return {
    type: Actions.TASKS_READY_LOADING_START,
  };
};

const tasksReadyLoadingStop = () => {
  return {
    type: Actions.TASKS_READY_LOADING_STOP,
  };
};

const fetchTasksSuccess = (tasks, state) => {
  return {
    type: Actions.FETCH_TASKS_SUCCESS,
    tasks,
    state,
  };
};

const fetchTasksRequest = (state) => {
  return {
    type: Actions.FETCH_TASKS_REQUEST,
    state,
  };
};

const fetchTasksError = (state) => {
  return {
    type: Actions.FETCH_TASKS_ERROR,
    state,
  };
};

//batch tasks
const fetchBatchTasksSuccess = (tasks) => {
  return {
    type: Actions.FETCH_BATCHTASKS_SUCCESS,
    tasks,
  };
};

const fetchBatchTasksRequest = () => {
  return {
    type: Actions.FETCH_BATCHTASKS_REQUEST,
  };
};

const fetchBatchTasksError = () => {
  return {
    type: Actions.FETCH_BATCHTASKS_ERROR,
  };
};

export const addTaskFromPrototype = (t, prototypeId, task) => {
  return (dispatch) => {
    return axios
      .post(`/prototypes/${prototypeId}/task`, task)
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully added new task"),
          })
        );
        return response;
      });
  };
};

export const addRecurrenceTask = (t, task) => {
  return (dispatch) => {
    return axios
      .post(`/taskSchedulers`, task)
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully added new task"),
          })
        );
        return response;
      })
      .catch((error) => {
        console.log("ERROR", error);
        if (error.response) {
          if (error.response.status === 409) {
            dispatch(
              createToast({
                type: "error",
                title: t(
                  "this asset already has scheduled task based on selected task type"
                ),
              })
            );
          }
        }
      });
  };
};

export const fetchTasksList = (
  state,
  rootIds,
  taskPrototypeIds,
  taskStates,
  from,
  to
) => {
  if (state === "TODO") {
    return (dispatch) => {
      dispatch(tasksRecentLoadingStart());
      return axios
        .post(`/tasks/getOpenTasks`, {
          rootIds: rootIds ? rootIds : [],
          taskPrototypeIds: taskPrototypeIds ? taskPrototypeIds : [],
          taskStates: taskStates ? taskStates : [],
          from: from || null,
          to: to || null,
        })
        .then((response) => {
          dispatch(fetchTasksSuccess(response.data, state));
          dispatch(tasksRecentLoadingStop());
        })
        .catch((error) => {
          dispatch(fetchTasksError(error, state));
        });
    };
  } else if (state === "DONE") {
    return (dispatch) => {
      dispatch(tasksReadyLoadingStart());
      return axios
        .post(`/tasks/getClosedTasks`, {
          rootIds: rootIds ? rootIds : [],
          taskPrototypeIds: taskPrototypeIds ? taskPrototypeIds : [],
          taskStates: taskStates ? taskStates : [],
          from: from || null,
          to: to || null,
        })
        .then((response) => {
          dispatch(fetchTasksSuccess(response.data, state));
          dispatch(tasksReadyLoadingStop());
        })
        .catch((error) => {
          dispatch(fetchTasksError(error, state));
        });
    };
  }
};

export const taskCannotBeDone = (t, id, reason = "cant do") => {
  return (dispatch) => {
    dispatch(tasksLoadingStart());
    return axios
      .post(`/tasks/${id}/cantDo?reason=${reason}`)
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully changed task status"),
          })
        );
        dispatch(tasksLoadingStop());
        return response;
      });
  };
};

export const updateBatchTask = (t, update) => {
  return (dispatch) => {
    dispatch(tasksLoadingStart());
    return axios.post(`/tasks/submit`, update).then(() => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully finished task"),
        })
      );
      dispatch(tasksLoadingStop());
    });
  };
};
export const updateFinishedTask = (t, update) => {
  return (dispatch) => {
    dispatch(tasksLoadingStart());
    return axios.put(`/tasks`, update).then(() => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully edited task"),
        })
      );
      dispatch(tasksLoadingStop());
    });
  };
};
export const updateState = (t, id, event, reason) => {
  return (dispatch) => {
    dispatch(tasksLoadingStart());
    return axios
      .put(`/tasks/change_state?id=${id}&event=${event}&reason=${reason}`)
      .then(() => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully finished task"),
          })
        );
        dispatch(tasksLoadingStop());
      });
  };
};

export const clearBatchTaskList = () => {
  return {
    type: Actions.CLEAR_BATCHTASKS_REQUEST,
  };
};

export const fetchBatchTasksList = (taskPrototypeId, buildingIds = []) => {
  return (dispatch) => {
    dispatch(fetchBatchTasksRequest());
    return axios
      .post(`/tasks/batchTasks?taskPrototypeId=${taskPrototypeId}`, buildingIds)
      .then((response) => {
        dispatch(fetchBatchTasksSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchBatchTasksError(error));
      });
  };
};

//group task

export const fetchGroupTasks = (
  assetRootIds = [],
  taskPrototypeIds = [],
  statuses = [],
  sortBy = "ASSET_PATH"
) => {
  const body = {
    assetRootIds,
    taskPrototypeIds,
    statuses,
    sortBy,
    // isSingle: false,
  };
  return (dispatch) => {
    const url = `/taskPool/get`;

    return axios.post(url, body).then((response) => {
      dispatch(fetchGroupTaskSuccess(response.data));
    });
  };
};

const fetchGroupTaskSuccess = (groupTasks) => {
  return {
    type: Actions.FETCH_GROUP_TASKS_SUCCESS,
    groupTasks,
  };
};

export const addGroupTask = (
  t,
  taskPrototypeId,
  assetIds,
  activeCount,
  startDate,
  dueDate,
  parentId,
  assetPrototypeId,
  confirmRemoveAssets,
  confirmCreateAssets
) => {
  return (dispatch) => {
    const url = `/taskPool`;

    return axios
      .post(url, {
        taskPrototypeId,
        assetIds,
        activeCount,
        startDate,
        dueDate,
        parentId,
        assetPrototypeId,
        confirmRemoveAssets,
        confirmCreateAssets,
      })
      .then((response) => {
        if (response.status !== 202) {
          dispatch(
            createToast({
              type: "success",
              title: t("successfully added group tasks"),
            })
          );
        }
        return response;
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 409) {
            dispatch(
              createToast({
                type: "error",
                title: t(
                  "this asset already has a scheduled multi-asset task type based on selected task type"
                ),
              })
            );
          }
        } else {
          dispatch(
            createToast({
              type: "error",
              title: t("adding group tasks failed"),
            })
          );
        }
      });
  };
};

export const editGroupTask = (
  t,
  taskPrototypeId,
  assetIds,
  activeCount,
  startDate,
  dueDate,
  parentId,
  assetPrototypeId,
  confirmRemoveAssets,
  confirmCreateAssets,
  id
) => {
  return (dispatch) => {
    const url = `/taskPool`;

    return axios
      .put(url, {
        id,
        taskPrototypeId,
        assetIds,
        activeCount,
        startDate,
        dueDate,
        parentId,
        assetPrototypeId,
        confirmRemoveAssets,
        confirmCreateAssets,
      })
      .then((response) => {
        if (response.status !== 202) {
          dispatch(
            createToast({
              type: "success",
              title: t("successfully edited group tasks"),
            })
          );
        }
        return response;
      })
      .catch((error) => {
        dispatch(
          createToast({
            type: "error",
            title: t("editing group tasks failed"),
          })
        );
      });
  };
};

export const deleteGroupTask = (t, groupTaskId) => {
  return (dispatch) => {
    return axios.delete(`/taskPool/${groupTaskId}`).then(() => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully deleted tasks group"),
        })
      );
    });
  };
};

// get single task by id

export const getSingleTask = (taskId) => {
  return axios.get(`/tasks/${taskId}`);
};

export const getBatchTaskPrototypes = () => {
  return axios.get(`/taskPrototypes/batchTasks`);
};

// task undone
export const undoneTask = (t, id, reason) => {
  return (dispatch) => {
    dispatch(tasksLoadingStart());
    return axios.post(`/tasks/${id}/undo?reason=${reason}`).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully undone task"),
        })
      );
      dispatch(tasksLoadingStop());
      return response;
    });
  };
};

// cancel task
export const cancelTask = (t, id) => {
  return (dispatch) => {
    dispatch(tasksLoadingStart());
    return axios.post(`/tasks/${id}/cancel?reason=''`).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully cancelled task"),
        })
      );
      dispatch(tasksLoadingStop());
      return response;
    });
  };
};

export const fetchRecurrenceTasksList = (
  assetRootIds = [],
  taskPrototypeIds = [],
  statuses = [],
  sortBy = "ASSET_PATH"
) => {
  return (dispatch) => {
    const url = `/taskPool/get`;
    const body = {
      assetRootIds,
      taskPrototypeIds,
      statuses,
      sortBy,
      isRecursive: true,
    };
    dispatch(fetchRecurrenceTasksRequest());
    return axios
      .post(url, body)
      .then((response) => {
        dispatch(fetchRecurrenceTasksSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchRecurrenceTasksError(error));
      });
  };
};

export const fetchRecurrenceTasksForAsset = (
  assetId,
  taskPrototypeIds = [],
  statuses = [],
  sortBy = "ASSET_PATH"
) => {
  return (dispatch) => {
    const url = `/taskPool/get`;
    const body = {
      assetId,
      assetRootIds: [],
      taskPrototypeIds,
      statuses,
      sortBy,
    };
    dispatch(fetchRecurrenceTasksRequest());
    return axios
      .post(url, body)
      .then((response) => {
        dispatch(fetchRecurrenceTasksSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchRecurrenceTasksError(error));
      });
  };
};

const fetchRecurrenceTasksRequest = () => {
  return {
    type: Actions.FETCH_RECURRENCE_TASKS_REQUEST,
  };
};

const fetchRecurrenceTasksSuccess = (tasks) => {
  return {
    type: Actions.FETCH_RECURRENCE_TASKS_SUCCESS,
    tasks,
  };
};

const fetchRecurrenceTasksError = (error) => {
  return {
    type: Actions.FETCH_RECURRENCE_TASKS_ERROR,
    error,
  };
};

export const exportTasksTemplate = (t, taskPrototypeIds, rootId) => {
  return (dispatch) => {
    return axios
      .post(`/tasks/import/template`, {
        taskPrototypeIds,
        rootId,
      })
      .then(({ data }) => {
        downloadFile(data);
      })
      .catch((error) => {
        dispatch(
          createToast({
            type: "error",
            title: t("exporting tasks template failed"),
          })
        );
      });
  };
};

export const importTasks = (t, values, rootId) => {
  const formData = new FormData();
  values.files.forEach((file) => {
    formData.append("file", file, file.name);
  });

  return async (dispatch) => {
    const { data } = await refreshToken();
    await dispatch(refreshSuccess(data));
    return axios
      .post(`/tasks/import?rootId=${rootId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch(fetchAssets());
        if (response?.data?.importSuccess) {
          dispatch(
            createToast({
              type: "success",
              title: t("import completed"),
            })
          );
        }
        if (
          !response?.data?.allImportFailed &&
          !response?.data?.importSuccess
        ) {
          dispatch(
            createToast({
              type: "warning",
              title: t(
                "import completed with error: check download folder for errors summary"
              ),
            })
          );
        }
        if (response?.data?.allImportFailed && !response?.data?.importSuccess) {
          dispatch(
            createToast({
              type: "error",
              title: t(
                "import failed: check download folder for errors summary"
              ),
            })
          );
        }
        if (response?.data?.file) {
          downloadFile(response.data.file);
        }
        return response;
      });
  };
};
export const importMultiTasks = (t, values) => {
  const formData = new FormData();
  values.files.forEach((file) => {
    formData.append("file", file, file.name);
  });

  return async (dispatch) => {
    const { data } = await refreshToken();
    await dispatch(refreshSuccess(data));
    return axios
      .post(`/tasks/import/multiple`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch(fetchAssets());
        if (response?.data?.importSuccess) {
          dispatch(
            createToast({
              type: "success",
              title: t("import completed"),
            })
          );
        }
        if (
          !response?.data?.allImportFailed &&
          !response?.data?.importSuccess
        ) {
          dispatch(
            createToast({
              type: "warning",
              title: t(
                "import completed with error: check download folder for errors summary"
              ),
            })
          );
        }
        if (response?.data?.allImportFailed && !response?.data?.importSuccess) {
          dispatch(
            createToast({
              type: "error",
              title: t(
                "import failed: check download folder for errors summary"
              ),
            })
          );
        }
        if (response?.data?.file) {
          downloadFile(response.data.file);
        }
        return response;
      });
  };
};
