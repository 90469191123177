import React from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import PropTypes from "prop-types";
import { Field } from "formik";
import styles from "./DatePicker.module.scss";

const ColorPicker = ({ name }) => {
  return (
    <Field name={name}>
      {({
        field,
        form: { touched, handleChange, handleBlur, setFieldTouched },
      }) => {
        const selected = !field.value ? "#000000" : field.value;
        return (
          <div className={styles.container}>
            <label className={styles.picker_wrapper}>
              <HexColorPicker
                color={selected}
                onChange={(value) => {
                  const eventFormik = { target: { name, value } };
                  handleChange(eventFormik);
                  setFieldTouched(name, true, false);
                }}
                onBlur={() => {
                  const eventFormik = { target: { name } };
                  handleBlur(eventFormik);
                }}
              />
              <HexColorInput
                color={selected}
                onChange={(value) => {
                  const eventFormik = { target: { name, value } };
                  handleChange(eventFormik);
                  setFieldTouched(name, true, false);
                }}
                onBlur={() => {
                  const eventFormik = { target: { name } };
                  handleBlur(eventFormik);
                }}
              />
            </label>
          </div>
        );
      }}
    </Field>
  );
};

ColorPicker.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ColorPicker;
