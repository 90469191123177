import React, { useContext } from "react";
import Modal from "../Modal";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./GroupTasksConflictsModal.module.scss";
import { Form, FormControl, RadioField } from "../Input";

const GroupTasksConflictsModal = ({
  onClose,
  isOpen,
  values,
  assetIds,
  conflictedAssets,
  handleCreate,
  edit,
}) => {
  const { t } = useContext(I18nContext);

  const conflictSolveOptions = [
    {
      label: t(
        "remove conflicted assets from selected assets list and create multi-asset task for remaining selected assets"
      ),
      value: "remove",
    },
    {
      label: t(
        "remove conflicted assets from existing schedules with selected task type and add them in this multi-asset task"
      ),
      value: "create",
    },
  ];

  const handleSubmit = async (values, formik) => {
    const newValues = {
      ...values,
      confirmCreateAssets: values.confirmRemoveAssets === "create",
      confirmRemoveAssets: values.confirmRemoveAssets === "remove",
    };

    await handleCreate(newValues, formik);
    onClose();
  };

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      lengthInfo
      divider
      title={t("solve multi-asset task assets conflicts")}
      form="solve-group-task-conflicts"
      editMode={edit}
    >
      <div className={styles.row}>
        {t(
          "following assets already have scheduled tasks based on selected task type"
        )}
      </div>
      <div className={styles.row}>
        {conflictedAssets.map((conflict) => (
          <div key={conflict.id} className={styles.list_row}>
            <div className={styles.asset_name}>{conflict.name}</div>
            <div className={styles.asset_path}>
              {conflict.path.slice(0, -1).join(" / ")}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.row}>
        {t("how would you like to handle these conflicts")}?
      </div>
      <div className={styles.row}>
        <Form
          id="solve-group-task-conflicts"
          onSubmit={handleSubmit}
          initialValues={{
            ...values,
            confirmRemoveAssets:
              assetIds.length === conflictedAssets.length ? "create" : "remove",
          }}
          enableReinitialize={true}
          disabled={assetIds.length === conflictedAssets.length}
        >
          <FormControl>
            <RadioField
              options={conflictSolveOptions}
              name="confirmRemoveAssets"
              disabled={assetIds.length === conflictedAssets.length}
            />
          </FormControl>
        </Form>
      </div>
      {assetIds.length === conflictedAssets.length && (
        <div className={styles.row_error}>
          {t(
            "selection is limited to second option as all selected assets would be removed from the list if first option was applied"
          )}
        </div>
      )}
    </Modal>
  );
};
export default GroupTasksConflictsModal;
