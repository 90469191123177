import React, { useContext, useState, useEffect } from "react";
import PropertyType from "../../constants/PropertyType";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Icon from "../Icon";
import {
  Checkbox,
  CheckboxGroup,
  DatePicker,
  FieldError,
  FieldInput,
  FieldLabel,
  FieldSelect,
  FieldTextArea,
  FormControl,
  RadioField,
  SwitchInput,
} from "../Input";
import cx from "classnames";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./PrototypeForm.module.scss";
import Select from "react-select";
import PropertyDeleteConfirmation from "./PropertyDeleteConfirmation";
import {useSelector} from "react-redux";

const PrototypeForm = ({
  assetProperties,
  onDeleteProperty,
  onEditProperty,
  isCreating = false,
  className,
  disabled,
  allowDeletingProperties,
  editable,
  showDisplayedPropertiesSelector,
  selectDisplayedProperty,
  displayedProperties,
  noDefaultDateValue,
  allowDisplayedPropertiesEditing,
  changeOrder,
  taskPreview,
  notDraggable,
}) => {
  const { t } = useContext(I18nContext);
  const role = useSelector((state) => state.auth.info.role);
  const [showPropertyDeleteConfirmation, setShowPropertyDeleteConfirmation] = useState(false);
  const [propertyToDelete, setPropertyToDelete] = useState(null);

  useEffect(() => {
    setShowPropertyDeleteConfirmation(false);
    setPropertyToDelete(null);
  }, [assetProperties]);

  if (!assetProperties.length) {
    return null;
  }
  const classNames = cx(styles.background_form, className);

  const propertiesArray = assetProperties.map((prop) =>
    !!prop.current ? prop.current : prop
  );

  const allDisplayPropertyOptions = [
    { value: 0, label: t("do not display") },
    { value: 1, label: t("display as 1st") },
    { value: 2, label: t("display as 2nd") },
    { value: 3, label: t("display as 3rd") },
  ];

  const displayPropertyOptions = (property) => {
    const isPropertySelected = displayedProperties.includes(property);
    const propertyIndex = displayedProperties.indexOf(property);
    const selectedCount = displayedProperties.length;

    if (selectedCount === 0) {
      return [allDisplayPropertyOptions[0], allDisplayPropertyOptions[1]];
    } else if (isPropertySelected) {
      return [
        allDisplayPropertyOptions[0],
        allDisplayPropertyOptions[propertyIndex + 1],
      ];
    } else {
      return allDisplayPropertyOptions.slice(0, selectedCount + 2);
    }
  };

  const displayPropertyDeleteConfirmation = (property) => {
    setShowPropertyDeleteConfirmation(true);
    setPropertyToDelete(property);
  };

  const hidePropertyDeleteConfirmation = () => {
    setShowPropertyDeleteConfirmation(false);
    setPropertyToDelete(null);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(propertiesArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    changeOrder(items);
  };

  const isNotAdmin = role !== "SUPER_ADMIN" && role !== "ADMIN" && role !== "CUSTOMER_ADMIN"

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='properties'>
        {provided => (
          <div
            className={classNames}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {propertiesArray.map((property, index) => {
              let label = property.name.split("__").pop();
              const disabledProp =
                (!isCreating && !property.flags.isUpdatable && isNotAdmin) || Boolean(disabled);

              if (property.propertyType === PropertyType.text) {
                return (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                    isDragDisabled={!editable || notDraggable}
                  >
                    {provided => (
                      <div
                        key={property.id || property.name}
                        className={
                          !isCreating && !property.flags.isUpdatable && isNotAdmin
                            ? styles.disabled
                            : styles.property_item
                        }
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <FormControl taskPreview={taskPreview}>
                          <div className={styles.property_name_row}>
                            <div className={styles.delete_btn}>
                              <FieldLabel>
                                {label}
                                {property.flags.isMandatory && (
                                  <span className={styles.red}> *</span>
                                )}
                              </FieldLabel>
                              {onEditProperty && editable && (
                                <div
                                  className={styles.edit_btn}
                                  onClick={() =>
                                    onEditProperty(property, index)
                                  }
                                >
                                  <Icon className={styles.edit_icon} name="edit" />
                                </div>
                              )}
                              {onDeleteProperty && editable && allowDeletingProperties && (
                                <div
                                  className={styles.edit_btn}
                                  onClick={() => displayPropertyDeleteConfirmation(property.id || property.name)}
                                >
                                  <Icon className={styles.delete_icon} name="x-delete" />
                                </div>
                              )}
                              {showPropertyDeleteConfirmation && (propertyToDelete === property.id || propertyToDelete === property.name) && (
                                <PropertyDeleteConfirmation
                                  property={property}
                                  onConfirm={onDeleteProperty}
                                  onClose={hidePropertyDeleteConfirmation}
                                />
                              )}
                            </div>
                            {showDisplayedPropertiesSelector && (
                              <div className={styles.displayed_property_selector}>
                                <div className={styles.displayed_property_selector_label}>
                                  {t("set as displayed property")}
                                </div>
                                <div>
                                  <Select
                                    styles={customStyles}
                                    menuPosition={"relative"}
                                    value={
                                      allDisplayPropertyOptions[
                                        displayedProperties.indexOf(
                                          property.id || property.tempId
                                        ) + 1
                                      ]
                                    }
                                    options={displayPropertyOptions(
                                      property.id || property.tempId
                                    )}
                                    onChange={(selected) => {
                                      selectDisplayedProperty(
                                        selected.value,
                                        property.id || property.tempId
                                      );
                                    }}
                                    maxMenuHeight={200}
                                    isDisabled={disabled && !allowDisplayedPropertiesEditing}
                                    placeholder={t("select")}
                                    noOptionsMessage={() => t("no options")}
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          <FieldTextArea
                            name={property.id || property.name}
                            disabled={disabledProp}
                            data-testid={`textFiel-${property.id || property.name}`}
                          />

                          <FieldError name={property.id || property.name} />
                        </FormControl>
                      </div>
                    )}
                  </Draggable>
                );
              }
              if (property.propertyType === PropertyType.boolean) {
                return (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                    isDragDisabled={!editable || notDraggable}
                  >
                    {provided => (
                      <div
                        key={property.id || property.name}
                        className={
                          !isCreating && !property.flags.isUpdatable && isNotAdmin
                            ? styles.disabled
                            : styles.property_item
                        }
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <FormControl taskPreview={taskPreview}>
                          <div className={styles.property_name_row}>
                            <div className={styles.delete}>
                              <FieldLabel>
                                {label}
                                {property.flags.isMandatory && (
                                  <span className={styles.red}> *</span>
                                )}
                              </FieldLabel>
                              <div className={styles.delete_btn}>
                                {onEditProperty && editable && (
                                  <div
                                    className={styles.edit_btn}
                                    onClick={() =>
                                      onEditProperty(property, index)
                                    }
                                  >
                                    <Icon className={styles.edit_icon} name="edit" />
                                  </div>
                                )}
                                {onDeleteProperty && editable && allowDeletingProperties && (
                                  <div
                                    className={styles.edit_btn}
                                    onClick={() => displayPropertyDeleteConfirmation(property.id || property.name)}
                                  >
                                    <Icon className={styles.delete_icon} name="x-delete" />
                                  </div>
                                )}
                                {showPropertyDeleteConfirmation && (propertyToDelete === property.id || propertyToDelete === property.name) && (
                                  <PropertyDeleteConfirmation
                                    property={property}
                                    onConfirm={onDeleteProperty}
                                    onClose={hidePropertyDeleteConfirmation}
                                  />
                                )}
                              </div>
                            </div>
                            {showDisplayedPropertiesSelector && (
                              <div className={styles.displayed_property_selector}>
                                <div className={styles.displayed_property_selector_label}>
                                  {t("set as displayed property")}
                                </div>
                                <div>
                                  <Select
                                    styles={customStyles}
                                    menuPosition={"relative"}
                                    value={
                                      allDisplayPropertyOptions[
                                        displayedProperties.indexOf(
                                          property.id || property.tempId
                                        ) + 1
                                      ]
                                    }
                                    options={displayPropertyOptions(
                                      property.id || property.tempId
                                    )}
                                    onChange={(selected) => {
                                      selectDisplayedProperty(
                                        selected.value,
                                        property.id || property.tempId
                                      );
                                    }}
                                    maxMenuHeight={200}
                                    isDisabled={disabled && !allowDisplayedPropertiesEditing}
                                    placeholder={t("select")}
                                    noOptionsMessage={() => t("no options")}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={styles.width}>
                            <SwitchInput
                              disabled={disabledProp}
                              name={property.id || property.name}
                              trueLabel={property.trueLabel}
                              falseLabel={property.falseLabel}
                              trueColor={property.trueColor}
                              falseColor={property.falseColor}
                            />
                          </div>
                          <FieldError name={property.id || property.name} />
                        </FormControl>
                      </div>
                    )}
                  </Draggable>
                );
              }
              if (property.propertyType === PropertyType.selection) {
                return (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                    isDragDisabled={!editable || notDraggable}
                  >
                    {provided => (
                      <div
                        key={property.id || property.name}
                        className={
                          !isCreating && !property.flags.isUpdatable && isNotAdmin
                            ? styles.disabled
                            : styles.property_item
                        }
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <FormControl taskPreview={taskPreview}>
                          <div className={styles.property_name_row}>
                            <div className={styles.delete}>
                              <FieldLabel>
                                {label}
                                {property.flags.isMandatory && (
                                  <span className={styles.red}> *</span>
                                )}
                              </FieldLabel>
                              <div className={styles.delete_btn}>
                                {onEditProperty && editable && (
                                  <div
                                    className={styles.edit_btn}
                                    onClick={() =>
                                      onEditProperty(property, index)
                                    }
                                  >
                                    <Icon className={styles.edit_icon} name="edit" />
                                  </div>
                                )}
                                {onDeleteProperty && editable && allowDeletingProperties && (
                                  <div
                                    className={styles.edit_btn}
                                    onClick={() => displayPropertyDeleteConfirmation(property.id || property.name)}
                                  >
                                    <Icon className={styles.delete_icon} name="x-delete" />
                                  </div>
                                )}
                                {showPropertyDeleteConfirmation && (propertyToDelete === property.id || propertyToDelete === property.name) && (
                                  <PropertyDeleteConfirmation
                                    property={property}
                                    onConfirm={onDeleteProperty}
                                    onClose={hidePropertyDeleteConfirmation}
                                  />
                                )}
                              </div>
                            </div>
                            {showDisplayedPropertiesSelector && (
                              <div className={styles.displayed_property_selector}>
                                <div className={styles.displayed_property_selector_label}>
                                  {t("set as displayed property")}
                                </div>
                                <div>
                                  <Select
                                    styles={customStyles}
                                    menuPosition={"relative"}
                                    value={
                                      allDisplayPropertyOptions[
                                        displayedProperties.indexOf(
                                          property.id || property.tempId
                                        ) + 1
                                      ]
                                    }
                                    options={displayPropertyOptions(
                                      property.id || property.tempId
                                    )}
                                    onChange={(selected) => {
                                      selectDisplayedProperty(
                                        selected.value,
                                        property.id || property.tempId
                                      );
                                    }}
                                    maxMenuHeight={200}
                                    isDisabled={disabled && !allowDisplayedPropertiesEditing}
                                    placeholder={t("select")}
                                    noOptionsMessage={() => t("no options")}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={styles.width}>
                            {property.multiselect && !property.dropdown && (
                              <CheckboxGroup
                                options={property.options.map((option) => ({
                                  label: option,
                                  value: option,
                                }))}
                                disabled={disabledProp}
                                name={property.id || property.name}
                                taskPreview={taskPreview}
                              />
                            )}
                            {!property.multiselect && !property.dropdown && (
                              <RadioField
                                options={property.options.map((option) => ({
                                  label: option,
                                  value: option,
                                }))}
                                disabled={disabledProp}
                                name={property.id || property.name}
                                taskPreview={taskPreview}
                              />
                            )}
                            {property.dropdown && (
                              <FieldSelect
                                size="s"
                                options={property.options.map((option) => ({
                                  label: option,
                                  value: option,
                                }))}
                                disabled={disabledProp}
                                name={property.id || property.name}
                              />
                            )}
                          </div>
                          <FieldError name={property.id || property.name} />
                        </FormControl>
                      </div>
                    )}
                  </Draggable>
                );
              }
              if (property.propertyType === PropertyType.date) {
                return (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                    isDragDisabled={!editable || notDraggable}
                  >
                    {provided => (
                      <div
                        key={property.id || property.name}
                        className={
                          !isCreating && !property.flags.isUpdatable && isNotAdmin
                            ? styles.disabled
                            : styles.property_item
                        }
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <FormControl taskPreview={taskPreview}>
                          <div className={styles.property_name_row}>
                            <div className={styles.delete}>
                              <FieldLabel>
                                {label}
                                {property.flags.isMandatory && (
                                  <span className={styles.red}> *</span>
                                )}
                              </FieldLabel>
                              {onEditProperty && editable && (
                                <div
                                  className={styles.edit_btn}
                                  onClick={() =>
                                    onEditProperty(property, index)
                                  }
                                >
                                  <Icon className={styles.edit_icon} name="edit" />
                                </div>
                              )}
                              {onDeleteProperty && editable && allowDeletingProperties && (
                                <div
                                  className={styles.edit_btn}
                                  onClick={() => displayPropertyDeleteConfirmation(property.id || property.name)}
                                >
                                  <Icon className={styles.delete_icon} name="x-delete" />
                                </div>
                              )}
                              {showPropertyDeleteConfirmation && (propertyToDelete === property.id || propertyToDelete === property.name) && (
                                <PropertyDeleteConfirmation
                                  property={property}
                                  onConfirm={onDeleteProperty}
                                  onClose={hidePropertyDeleteConfirmation}
                                />
                              )}
                            </div>
                            {showDisplayedPropertiesSelector && (
                              <div className={styles.displayed_property_selector}>
                                <div className={styles.displayed_property_selector_label}>
                                  {t("set as displayed property")}
                                </div>
                                <div>
                                  <Select
                                    styles={customStyles}
                                    menuPosition={"relative"}
                                    value={
                                      allDisplayPropertyOptions[
                                        displayedProperties.indexOf(
                                          property.id || property.tempId
                                        ) + 1
                                      ]
                                    }
                                    options={displayPropertyOptions(
                                      property.id || property.tempId
                                    )}
                                    onChange={(selected) => {
                                      selectDisplayedProperty(
                                        selected.value,
                                        property.id || property.tempId
                                      );
                                    }}
                                    maxMenuHeight={200}
                                    isDisabled={disabled && !allowDisplayedPropertiesEditing}
                                    placeholder={t("select")}
                                    noOptionsMessage={() => t("no options")}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={styles.date_section}>
                            <DatePicker
                              name={property.id || property.name}
                              disabled={disabledProp}
                              noDefaultValue={noDefaultDateValue}
                            />
                          </div>
                          <FieldError name={property.id || property.name} />
                        </FormControl>
                      </div>
                    )}
                  </Draggable>
                );
              }
              if (property.propertyType === PropertyType.numeric) {
                return property.hasRange ? (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                    isDragDisabled={!editable || notDraggable}
                  >
                    {provided => (
                      <div
                        key={property.id || property.name}
                        className={
                          !isCreating && !property.flags.isUpdatable && isNotAdmin
                            ? styles.disabled
                            : styles.property_item
                        }
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <FormControl taskPreview={taskPreview}>
                          <div className={styles.property_name_row}>
                            <span className={styles.delete_btn}>
                              <FieldLabel>
                                {label}
                                {property.flags.isMandatory && (
                                  <span className={styles.red}> *</span>
                                )}
                              </FieldLabel>
                              {onEditProperty && editable && (
                                <div
                                  className={styles.edit_btn}
                                  onClick={() =>
                                    onEditProperty(property, index)
                                  }
                                >
                                  <Icon className={styles.edit_icon} name="edit" />
                                </div>
                              )}
                              {onDeleteProperty && editable && allowDeletingProperties && (
                                <div
                                  className={styles.edit_btn}
                                  onClick={() => displayPropertyDeleteConfirmation(property.id || property.name)}
                                >
                                  <Icon className={styles.delete_icon} name="x-delete" />
                                </div>
                              )}
                              {showPropertyDeleteConfirmation && (propertyToDelete === property.id || propertyToDelete === property.name) && (
                                <PropertyDeleteConfirmation
                                  property={property}
                                  onConfirm={onDeleteProperty}
                                  onClose={hidePropertyDeleteConfirmation}
                                />
                              )}
                            </span>
                            {showDisplayedPropertiesSelector && (
                              <div className={styles.displayed_property_selector}>
                                <div className={styles.displayed_property_selector_label}>
                                  {t("set as displayed property")}
                                </div>
                                <div>
                                  <Select
                                    styles={customStyles}
                                    menuPosition={"relative"}
                                    value={
                                      allDisplayPropertyOptions[
                                        displayedProperties.indexOf(
                                          property.id || property.tempId
                                        ) + 1
                                      ]
                                    }
                                    options={displayPropertyOptions(
                                      property.id || property.tempId
                                    )}
                                    onChange={(selected) => {
                                      selectDisplayedProperty(
                                        selected.value,
                                        property.id || property.tempId
                                      );
                                    }}
                                    maxMenuHeight={200}
                                    isDisabled={disabled && !allowDisplayedPropertiesEditing}
                                    placeholder={t("select")}
                                    noOptionsMessage={() => t("no options")}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div>
                            <div className={styles.row}>
                              <FieldInput
                                size="s"
                                type="number"
                                name={property.id || property.name}
                                disabled={disabledProp}
                              />
                              <div className={styles.row}>
                                {property.unit && (
                                  <span className={styles.unit}>
                                    {property.unit.symbol}
                                    {property.unit?.range
                                      ? ` (${property.unit.range.lowerBound} - ${property.unit.range.upperBound})`
                                      : ''
                                    }
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className={styles.row}>
                              <FieldError name={property.id || property.name} />
                            </div>
                          </div>
                        </FormControl>
                      </div>
                    )}
                  </Draggable>
                ) : (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                    isDragDisabled={!editable || notDraggable}
                  >
                    {provided => (
                      <div
                        key={property.id || property.name}
                        className={
                          !isCreating && !property.flags.isUpdatable && isNotAdmin
                            ? styles.disabled
                            : styles.property_item
                        }
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <FormControl taskPreview={taskPreview}>
                          <div className={styles.property_name_row}>
                            <div className={styles.delete_btn}>
                              <FieldLabel>
                                {label}
                                {property.flags.isMandatory && (
                                  <span className={styles.red}> *</span>
                                )}
                              </FieldLabel>
                              {onEditProperty && editable && (
                                <div
                                  className={styles.edit_btn}
                                  onClick={() =>
                                    onEditProperty(property, index)
                                  }
                                >
                                  <Icon className={styles.edit_icon} name="edit" />
                                </div>
                              )}
                              {onDeleteProperty && editable && allowDeletingProperties && (
                                <div
                                  className={styles.edit_btn}
                                  onClick={() => displayPropertyDeleteConfirmation(property.id || property.name)}
                                >
                                  <Icon className={styles.delete_icon} name="x-delete" />
                                </div>
                              )}
                              {showPropertyDeleteConfirmation && (propertyToDelete === property.id || propertyToDelete === property.name) && (
                                <PropertyDeleteConfirmation
                                  property={property}
                                  onConfirm={onDeleteProperty}
                                  onClose={hidePropertyDeleteConfirmation}
                                />
                              )}
                            </div>
                            {showDisplayedPropertiesSelector && (
                              <div className={styles.displayed_property_selector}>
                                <div className={styles.displayed_property_selector_label}>
                                  {t("set as displayed property")}
                                </div>
                                <div>
                                  <Select
                                    styles={customStyles}
                                    menuPosition={"relative"}
                                    value={
                                      allDisplayPropertyOptions[
                                        displayedProperties.indexOf(
                                          property.id || property.tempId
                                        ) + 1
                                      ]
                                    }
                                    options={displayPropertyOptions(
                                      property.id || property.tempId
                                    )}
                                    onChange={(selected) => {
                                      selectDisplayedProperty(
                                        selected.value,
                                        property.id || property.tempId
                                      );
                                    }}
                                    maxMenuHeight={200}
                                    isDisabled={disabled && !allowDisplayedPropertiesEditing}
                                    placeholder={t("select")}
                                    noOptionsMessage={() => t("no options")}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={styles.number}>
                            <FieldInput
                              size="s"
                              type="number"
                              name={property.id || property.name}
                              disabled={disabledProp}
                            />
                            {property.unit && (
                              <span className={styles.unit}>
                                {property.unit.symbol}
                                {property.unit?.range
                                  ? ` (${property.unit.range.lowerBound} - ${property.unit.range.upperBound})`
                                  : ''
                                }
                              </span>
                            )}
                            <FieldError name={property.id || property.name} />
                          </div>
                        </FormControl>
                      </div>
                    )}
                </Draggable>
                );
              }
              return null;
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

PrototypeForm.propTypes = {
  onNext: PropTypes.func,
  className: PropTypes.string,
};

export default PrototypeForm;

const focusColor = "#EE7203";

const customStyles = {
  container: (provided, state, base) => {
    return {
      ...base,
      ...provided,
      width: "150px",
      height: "38px",
    };
  },
  control: (base, state, provided) => ({
    ...provided,
    paddingLeft: "0px",
    fontWeight: "400",
    fontSize: "14px",
    height: "38px",
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? focusColor : base.borderColor,
    "&:hover": {
      borderColor: state.isFocused ? focusColor : base.borderColor,
      cursor: "pointer",
    },
  }),
  option: (provided, state, base) => {
    return {
      ...base,
      ...provided,
      display: "flex",
      alignItems: "center",
      color: "#393939",
      backgroundColor: "#ffffff",
      fontSize: "14px",
      fontWeight: state.isSelected ? "500" : "400",
      height: "auto",
      "&:hover": {
        backgroundColor: "#eaeaea",
        color: "#393939",
        fontWeight: "500",
        cursor: "pointer",
      },
    };
  },
  valueContainer: (base, state, provided) => ({
    ...provided,
    ...base,
    backgroundColor: state.isDisabled ? "#f4f4f4" : "#ffffff",
    borderRadius: "3px",
    height: "36px",
  }),
  menu: (provided) => {
    return {
      ...provided,
      zIndex: "10",
      border: `1px solid ${focusColor}`,
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      height: "18px",
      marginLeft: "2px",
    };
  },
  indicatorSeparator: (provided) => {
    return {
      ...provided,
      backgroundColor: "none",
    };
  },
  dropdownIndicator: (base, state) => {
    return {
      ...base,
      color: state.isFocused ? "#EE7203" : "#AAAAAA",
    };
  },
};
