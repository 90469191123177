import PropertyType from "../../constants/PropertyType";

const updateFormValue = (current, property) => {
  if (property.propertyType === PropertyType.boolean) {
    current[property.id] = property.state;
  }
  if (property.propertyType === PropertyType.text) {
    current[property.id] = property.contents;
  }
  if (property.propertyType === PropertyType.selection) {
    current[property.id] = property.multiselect
      ? property.chosenOptions
      : property.chosenOptions?.[0];
  }
  if (property.propertyType === PropertyType.date) {
    current[property.id] = property.selectedDate;
  }
  if (property.propertyType === PropertyType.numeric) {
    if (property.hasRange) {
      current[property.id] = property.value;
    } else {
      current[property.id] = property.value;
    }
  }
};

const checkIfIsObject = (value) => {
  return typeof value === "object" && !Array.isArray(value) && value !== null;
};

export const getInitialState = (assetProperties, initialState) => {
  const properties = checkIfIsObject(assetProperties)
    ? assetProperties.properties
    : assetProperties;

  if (!properties.length) {
    return initialState;
  }

  return properties.reduce(
    (current, property) => {
      let prop = !!property.property ? property.property : property;

      if (property.id) {
        prop.id = property.id;
      }

      // if (
      //   current[prop.id] ||
      //   current[`${prop.id}_lowerBound`] ||
      //   current[`${prop.id}_upperBound`]
      // ) {
      updateFormValue(current, prop);
      // }

      // if (prop.propertyType === PropertyType.composite) {
      //   Object.values(prop.elements).forEach((element) => {
      //     updateFormValue(current, element);
      //   });
      // } else {
      //   updateFormValue(current, prop);
      // }
      return current;
    },
    { ...initialState }
  );
};

export const addFormIdToFields = (fields, formId) => {
  return fields.map((field) => {
    return {
      ...field,
      name: prefixWithName(field.name, formId),
      id: field.id.replaceAll(".", "___")
    };
  });
};

export const getAssetPropertiesFromForms = (forms) => {
  const assetProperties = [];
  forms.forEach((form) => {
    assetProperties.push(...addFormIdToFields(form.properties, form.id));
  });

  return assetProperties;
};

export const getInitialStateFromForms = (forms, initialValues) => {
  const assetProperties = getAssetPropertiesFromForms(forms);
  return getInitialState(assetProperties, initialValues);
};

export const convertToNameList = (list) => {
  return list.map((item) => {
    if (typeof item === "string") {
      return item;
    }
    return item.name;
  });
};

const updateValue = (property, name, value) => {
  if (property.propertyType === PropertyType.boolean) {
    property.state = Array.isArray(value) ? undefined : value;
  }
  if (property.propertyType === PropertyType.text) {
    property.contents = Array.isArray(value) ? undefined : value;
  }
  if (property.propertyType === PropertyType.selection) {
    property.chosenOptions = Array.isArray(value) ? value : [value];
  }
  if (property.propertyType === PropertyType.date) {
    property.selectedDate = Array.isArray(value) ? undefined : value;
  }
  if (property.propertyType === PropertyType.numeric) {
    property.value = Array.isArray(value) ? undefined : value;
  }
};

const updateProperty = (property, values, formId) => {
  if (
    !!property.property && !property.id
      ? property.property.id
      : property.id in values
  ) {
    const propName = prefixWithName(
      !!property.property ? property.property.name : property.name,
      formId
    );
    const propId =
      !!property.property && !property.id ? property.property.id : property.id;
    updateValue(
      !!property.property ? property.property : property,
      propName,
      values[propId]
    );
  }
};

export const updateAssetProperties = (assetProperties, values, formId) => {
  if (!assetProperties) {
    throw new Error("No assetProperties to update");
  }
  const copy = [...assetProperties];

  return copy.map((property) => {
    if (property.elements) {
      Object.values(property.elements).forEach((property) => {
        updateProperty(property, values, formId);
      });
    } else {
      updateProperty(property, values, formId);
    }

    return property;
  });
};

export const updateFormProperties = (forms, values) => {
  return forms.map((form) => {
    return {
      ...form,
      properties: updateAssetProperties(form.properties, values, form.id),
    };
  });
};

export const createSelectionProperty = (propertyType, values) => {
  return {
    propertyType: "selection",
    multiselect: propertyType === "selection-multi",
    dropdown: propertyType === "selection-dropdown",
    name: values.name,
    description: "some description",
    chosenOptions: values.chosenOptions ?? [],
    options: values.options,
    flags: {
      isConfigurable: true,
      isMandatory: values.isMandatory,
      isUpdatable: values.isUpdatable,
    },
    isActive: true,
  };
};

export const createBooleanProperty = (propertyType, values) => {
  return {
    propertyType: "boolean",
    state: false,
    name: values.name,
    description: "some description",
    trueLabel: values.trueLabel,
    falseLabel: values.falseLabel,
    trueColor: values.trueColor,
    falseColor: values.falseColor,
    flags: {
      isConfigurable: true,
      isMandatory: values.isMandatory,
      isUpdatable: true,
    },
    isActive: true,
  };
};

export const createNumericProperty = (propertyType, values, units) => {
  let chosenUnit;
  const unit = values.unit;
  const bluetooth =
    unit === 'AVG' ||
    unit === 'MIN' ||
    unit === 'MAX' ||
    unit === 'START' ||
    unit === 'END' ||
    unit === 'DURATION';

  if (unit === 'AVG' || unit === 'MIN' || unit === 'MAX' || unit === 'START' || unit === 'END') {
    chosenUnit = {
      id: unit,
      name: "",
      symbol: "°C",
      range: {
        "lowerBound": 0,
        "upperBound": 100,
        "step": 0
      },
      global: true
    };
  } else if (unit === 'DURATION') {
    chosenUnit = {
      id: unit,
      name: "",
      symbol: "s",
      range: {
        "lowerBound": 0,
        "upperBound": 300,
        "step": 0
      },
      global: true
    };
  } else {
    chosenUnit = units.find((el) => el.id === values.unit);
  }

  return {
    propertyType: PropertyType.numeric,
    name: values.name,
    value: values.selected,
    hasRange: propertyType === "numeric-range",
    range:
      propertyType === "numeric-range"
        ? {
            step: 1,
            lowerBound: values.lowerBound,
            upperBound: values.upperBound,
          }
        : undefined,
    description: "some description",
    flags: {
      isConfigurable: true,
      isMandatory: values.isMandatory,
      isUpdatable: values.isUpdatable,
    },
    unit: chosenUnit,
    bluetoothMeasurementType: bluetooth ? unit : 'NONE',
    isActive: true,
  };
};

export const createOtherProperty = (propertyType, values) => {
  return {
    propertyType: propertyType,
    name: values.name,
    description: "some description",
    flags: {
      isConfigurable: true,
      isMandatory: values.isMandatory,
      isUpdatable: values.isUpdatable,
    },
    isActive: true,
  };
};

export const prefixWithName = (propertyName, prefix) => {
  return prefix ? `${prefix}__${propertyName}` : propertyName;
};
