import React, { useEffect, useState, useRef, useContext } from "react";
import * as Yup from "yup";
import {
  FieldLabel,
  Form,
  FormControl,
  FieldError,
  FieldInput,
} from "../Input";
import { useDispatch, useSelector } from "react-redux";
import { getInitialState, PrototypeForm } from "../PrototypeForm";
import FieldBuilder from "../FieldBuilder";
import PropertyType from "../../constants/PropertyType";
import {
  addForm,
  updateForm,
  fetchForms,
  formsEditErrorClear,
} from "../../redux";
import {
  createBooleanProperty,
  createNumericProperty,
  createOtherProperty,
  createSelectionProperty,
  updateAssetProperties,
} from "../PrototypeForm/utils";
import styles from "./FormsEditForm.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import Button from "../Button";
import Loader from "../Loader";
import EditAssetPropertyModal from "./EditAssetPropertyModal";
import FormEditErrorModal from "./FormEditErrorModal";

const defaultInitialValues = {
  formNameUnique: "",
};

const createValidationScheme = (t, forms) => {
  // const propertiesSchema = sections
  //   .map((section) => section.fields)
  //   .flat()
  //   .reduce((current, { property }) => {
  //     if (property.flags.isMandatory) {
  //       current[property.name] = Yup.mixed().required(
  //         t("this field is required, please provide a value.")
  //       );
  //     }
  //     return current;
  //   }, {});

  return Yup.object({
    formNameUnique: Yup.mixed()
      // .notOneOf(forms, t("name must be unique"))
      .required(t("please enter the name of the form")),
    // ...propertiesSchema,
  });
};

const isEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

const FormsEditForm = ({
  onClose,
  formId = "form-modal-form",
  formPrototype,
  disabled,
  notEditable,
  updateSelectedForm,
}) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const currentValues = useRef(defaultInitialValues);
  const [fields, setFields] = useState([]);
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [isPropertyEdited, setIsPropertyEdited] = useState(false);
  const [showPropertyEditedWarning, setShowPropertyEditedWarning] =
    useState(false);
  const [formikContent, setFormikContent] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedProperty, setEditedProperty] = useState(null);
  const [editedPropertyIndex, setEditedPropertyIndex] = useState(null);
  const [showFormEditErrorModal, setShowFormEditErrorModal] = useState(false);
  const [showFieldBuilder, setShowFieldBuilder] = useState(false);

  const forms = useSelector((state) => state.forms.items.map((p) => p.name));
  const formsLoading = useSelector((state) => state.forms.loading);
  const formEditErrorStatus = useSelector(
    (state) => state.forms.formEditErrorStatus
  );
  const formEditError = useSelector((state) => state.forms.formEditError);

  const validationSchema = createValidationScheme(t, forms);

  const unitsOptions = useSelector((state) => state.units.items);

  useEffect(() => {
    dispatch(formsEditErrorClear());
  }, []);

  useEffect(() => {
    if (formEditErrorStatus === 400) {
      setShowFormEditErrorModal(true);
    } else {
      dispatch(formsEditErrorClear());
    }
  }, [formEditError]);

  const handleSubmit = async (
    values,
    formik,
    overridePropertyEditedWarning
  ) => {
    if (isPropertyEdited && !overridePropertyEditedWarning) {
      setShowPropertyEditedWarning(true);
      setFormikContent({ values, formik });
      return null;
    }

    if (formPrototype) {
      const objToSend = {
        ...formPrototype,
        name: values.formNameUnique,
        properties: updateAssetProperties(fields, values),
      };
      const { data } = await dispatch(updateForm(t, objToSend));
    } else {
      const objToSend = {
        name: values.formNameUnique,
        properties: updateAssetProperties(fields, values),
      };
      await dispatch(addForm(t, objToSend));
    }

    await dispatch(fetchForms());
    formik.resetForm();
    handleClose();
  };

  const handleClose = () => {
    setInitialValues(defaultInitialValues);
    setFields([]);
    onClose();
  };

  useEffect(() => {
    if (formPrototype) {
      const { name } = formPrototype;
      currentValues.current = { formNameUnique: name };

      setInitialValues({ formNameUnique: name });
      setTimeout(() => {
        setFields(formPrototype.properties);
      }, 100);
    }
  }, [formPrototype]);

  useEffect(() => {
    const assetProperties = fields.flat();
    setInitialValues(getInitialState(assetProperties, currentValues.current));
  }, [fields]);

  const handleSubmitField = (values, formik) => {
    const { property, tags = [] } = values;
    const { propertyType } = property;
    const newFields = [...fields];

    if (propertyType.startsWith(PropertyType.selection)) {
      if (property.options.length === 0) {
        return;
      }
      newFields.push({
        tags,
        ...createSelectionProperty(propertyType, property),
        updatable: property.isUpdatable,
      });
    } else if (propertyType.startsWith(PropertyType.numeric)) {
      newFields.push({
        tags,
        ...createNumericProperty(propertyType, property, unitsOptions),
        updatable: property.isUpdatable,
      });
    } else if (propertyType.startsWith(PropertyType.boolean)) {
      newFields.push({
        tags,
        ...createBooleanProperty(propertyType, property, unitsOptions),
        updatable: property.isUpdatable,
      });
    } else {
      newFields.push({
        tags,
        ...createOtherProperty(propertyType, property),
        updatable: property.isUpdatable,
      });
    }
    setFields(newFields);
    formik.resetForm();
    setShowFieldBuilder(false);
  };

  const handleEditPropertyClick = (property, index) => {
    setIsEditModalOpen(true);
    setEditedProperty(property);
    setEditedPropertyIndex(index);
  };

  const handleEditProperty = (values, formik) => {
    const { property = {} } = values;
    const { propertyType } = property;

    const newProperties = [...fields];

    const index = editedPropertyIndex;

    const propertyName = property.name;

    if (propertyType.startsWith(PropertyType.selection)) {
      if (property.options.length === 0) {
        return;
      }
      newProperties[index] = createSelectionProperty(propertyType, property);
    } else if (propertyType.startsWith(PropertyType.numeric)) {
      newProperties[index] = createNumericProperty(
        propertyType,
        property,
        unitsOptions
      );
    } else if (propertyType.startsWith(PropertyType.boolean)) {
      newProperties[index] = createBooleanProperty(propertyType, property);
    } else {
      newProperties[index] = createOtherProperty(propertyType, property);
    }
    newProperties[index].name = propertyName;
    if (editedProperty.id) {
      newProperties[index].id = editedProperty.id;
    }
    setFields(newProperties);
    setIsEditModalOpen(false);
    setEditedProperty(null);
    setEditedPropertyIndex(null);
    formik.resetForm();
  };

  const handleDeleteProperty = (property) => {
    const newFields = [...fields];

    const updatedFields = newFields.filter(
      (field) => field.name !== property.name
    );

    setFields(updatedFields);
  };

  const changePropertiesOrder = (newProperties) => {
    setFields(newProperties);
  };

  let propertyNames = [];

  propertyNames = fields.map((field) => {
    return field.name;
  });

  return (
    <>
      {formsLoading && <Loader />}
      <Form
        id={formId}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values }) => {
          if (!isEqual(currentValues.current, values)) {
            currentValues.current = values;
          }
          return (
            <div className={styles.form}>
              <div className={styles.row}>
                <FormControl>
                  <FieldLabel>
                    {t("name")}
                    <span className={styles.red}> *</span>
                  </FieldLabel>
                  <FieldInput
                    name="formNameUnique"
                    type="text"
                    placeholder={t("add form name")}
                    size="l"
                    disabled={notEditable}
                  />
                  <FieldError name="formNameUnique" />
                </FormControl>
              </div>
              <PrototypeForm
                disabled={disabled}
                assetProperties={fields.map((field) => field)}
                editable={!notEditable}
                allowDeletingProperties={!notEditable}
                onDeleteProperty={(property) => handleDeleteProperty(property)}
                onEditProperty={handleEditPropertyClick}
                noDefaultDateValue={true}
                changeOrder={changePropertiesOrder}
              />
            </div>
          );
        }}
      </Form>
      <hr className={styles.separator} />
      {!notEditable && !showFieldBuilder && (
        <Button
          variant="orange"
          size="s"
          onClick={() => setShowFieldBuilder(true)}
        >
          {t("new property")}
        </Button>
      )}
      {!notEditable && !!showFieldBuilder && (
        <FieldBuilder
          onSubmit={handleSubmitField}
          propertyNames={propertyNames}
          hideUpdatable={!formPrototype}
          setIsPropertyEdited={setIsPropertyEdited}
          setShowPropertyEditedWarning={setShowPropertyEditedWarning}
          formBuilder={true}
        />
      )}

      {showPropertyEditedWarning && (
        <div className={styles.property_edited_warning}>
          <span>
            {t("you have made changes, but have not added the property")}
            {". "}
            {t("are you sure you want to proceed")}
            {"?"}
          </span>
          <Button
            className={styles.btn_separator}
            variant="orange"
            size="s"
            onClick={() => {
              setShowPropertyEditedWarning(false);
              setIsPropertyEdited(false);
              handleSubmit(formikContent.values, formikContent.formik, true);
            }}
          >
            {t("confirm")}
          </Button>
          <Button
            className={styles.btn_separator}
            onClick={() => setShowPropertyEditedWarning(false)}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
        </div>
      )}

      {formPrototype && !!formEditError && showFormEditErrorModal && (
        <FormEditErrorModal
          onClose={() => {
            dispatch(formsEditErrorClear());
            setShowFormEditErrorModal(false);
          }}
          isOpen={showFormEditErrorModal}
          form={formPrototype}
          errors={formEditError}
        />
      )}

      {!!isEditModalOpen && (
        <EditAssetPropertyModal
          onSubmit={handleEditProperty}
          isOpen={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
            setEditedProperty(null);
            setEditedPropertyIndex(null);
          }}
          property={editedProperty}
          propertyNames={propertyNames}
          index={editedPropertyIndex}
          formEdit={true}
        />
      )}
    </>
  );
};

export default FormsEditForm;
