import React from "react";
import { Field } from "formik";
import PropTypes from "prop-types";
import styles from "./SwitchInput.module.scss";
import Switch from "react-switch";

const SwitchInput = ({
  name,
  trueLabel,
  trueColor,
  falseLabel,
  falseColor,
  disabled,
  ...rest
}) => {
  return (
    <div className={styles.container}>
      <Field name={name}>
        {({
          field,
          form: { touched, handleChange, handleBlur, setFieldTouched },
        }) => {
          const value = !field.value ? false : field.value;
          return (
            <div className={styles.container}>
              <div
                className={styles.label}
                style={{ color: falseColor }}
                onClick={() => {
                  const eventFormik = { target: { name, value: false } };
                  handleChange(eventFormik);
                  setFieldTouched(name, true, false);
                }}
              >
                {falseLabel}
              </div>
              <Switch
                touched={touched}
                height={24}
                width={48}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor={trueColor || "#64b90c"}
                offColor={falseColor || "#d2042d"}
                onChange={(value) => {
                  const eventFormik = { target: { name, value } };
                  handleChange(eventFormik);
                  setFieldTouched(name, true, false);
                }}
                onBlur={() => {
                  const eventFormik = { target: { name } };
                  handleBlur(eventFormik);
                }}
                checked={value}
              />
              <div
                className={styles.label}
                style={{ color: trueColor }}
                onClick={() => {
                  const eventFormik = { target: { name, value: true } };
                  handleChange(eventFormik);
                  setFieldTouched(name, true, false);
                }}
              >
                {trueLabel}
              </div>
            </div>
          );
        }}
      </Field>
    </div>
  );
};

SwitchInput.propTypes = {
  name: PropTypes.string.isRequired,
  trueLabel: PropTypes.string,
  falseLabel: PropTypes.string,
  trueColor: PropTypes.string,
  falseColor: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default SwitchInput;
