import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FieldInput,
  FieldSelect,
  FieldLabel,
  FormControl,
  FieldError,
} from "../Input";
import styles from "./NumericalBuilder.module.scss";
import { fetchUnitsList } from "../../redux";
import { I18nContext } from "../../i18n/I18nContext";

const NumericalBuilder = ({
  range,
  name,
  property,
  formProperty,
  bluetoothFieldTypeOptions,
}) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const unitsList = useSelector((state) => state.units.items);

  useEffect(() => {
    dispatch(fetchUnitsList());
  }, [dispatch]);

  const unitsOptions = formProperty
    ? [
        ...bluetoothFieldTypeOptions,
        ...(unitsList.length
          ? unitsList
              .filter((unit) => !unit.name.includes("bluetooth device"))
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((u) => ({
                label: `${u.name} ${t("in", "lower")} ${u.symbol} (${
                  u.range.lowerBound
                }-${u.range.upperBound})`,
                value: u.id,
              }))
          : []),
      ]
    : unitsList.length
    ? unitsList
        .filter((unit) => !unit.name.includes("bluetooth device"))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((u) => ({
          label: `${u.name} ${t("in", "lower")} ${u.symbol} (${
            u.range.lowerBound
          }-${u.range.upperBound})`,
          value: u.id,
        }))
    : [];

  return (
    <>
      {range && (
        <>
          <div className={styles.row}>
            <FormControl>
              <FieldLabel>{t("min")}</FieldLabel>
              <FieldInput
                size="xs"
                type="number"
                placeholder="Min"
                name={name ? `${name}.lowerBound` : "lowerBound"}
              />
              <FieldError name={name ? `${name}.lowerBound` : "lowerBound"} />
            </FormControl>
            <div className={styles.indicator}>-</div>
            <FormControl>
              <FieldLabel>{t("max")}</FieldLabel>
              <FieldInput
                size="xs"
                type="number"
                placeholder="Max"
                name={name ? `${name}.upperBound` : "upperBound"}
              />
              <FieldError name={name ? `${name}.upperBound` : "upperBound"} />
            </FormControl>
            <div className={styles.indicator}></div>
            <FormControl className={styles.units_container}>
              <FieldLabel>{t("units")}</FieldLabel>
              <FieldSelect
                placeholder="Units"
                size="s"
                name={name ? `${name}.unit` : "unit"}
                options={unitsOptions}
              />
              <FieldError name={name ? `${name}.unit` : "unit"} />
            </FormControl>
          </div>
        </>
      )}
      {!range && (
        <div className={styles.row}>
          <FormControl className={styles.units_container}>
            <FieldLabel>{t("units")}</FieldLabel>
            <FieldSelect
              placeholder="Units"
              size="s"
              // name="unit"
              name={name ? `${name}.unit` : "unit"}
              options={unitsOptions}
            />
            <FieldError name={name ? `${name}.unit` : "unit"} />
          </FormControl>
        </div>
      )}
    </>
  );
};

export default NumericalBuilder;
