import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAssets,
  updateAssetState,
  refreshToken,
  refreshSuccess,
  stateChangeStop,
} from "../../redux";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import styles from "./ToggleActivateModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import ActivityIndicator from "../../components/ActivityIndicator";

const ToggleActivateModal = ({ isOpen, onClose, asset }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const all = useSelector((state) => state.assets.allAssets);
  const loadingAssets = useSelector((state) => state.assets.loading);
  const statusChangeInProgress = useSelector(
    (state) => state.assets.stateChangeInProgress
  );
  const [loadingAssetsInProgress, setLoadingAssetsInProgress] = useState(false);

  useEffect(() => {
    dispatch(stateChangeStop());
  }, []);

  const handleFetchAssets = async (id) => {
    await dispatch(fetchAssets(id));
    const subAssets = all[id];
    if (!subAssets) return;
    const filtered = subAssets.filter((el) => el.grouping);
    await filtered.forEach((el) => {
      dispatch(fetchAssets(el.id));
    });
    return true;
  };

  const handleClear = () => {
    onClose();
  };
  const handleToggleActivation = async () => {
    const { data } = await refreshToken();
    await dispatch(refreshSuccess(data));

    if (asset.assetState === "ACTIVE") {
      await dispatch(updateAssetState(t, asset.id, "DEACTIVATE"));
    } else if (asset.assetState === "FOR_CREATION") {
      await dispatch(updateAssetState(t, asset.id, "ACTIVATE"));
    } else {
      await dispatch(updateAssetState(t, asset.id, "REACTIVATE"));
    }

    setLoadingAssetsInProgress(true);
    if (asset.grouping) {
      await handleFetchAssets(asset.id);
    }

    await dispatch(fetchAssets(asset.parentId));
    setLoadingAssetsInProgress(false);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(`change activation of asset`)}
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            variant="ghost"
            size="s"
          >
            {!!statusChangeInProgress ? t("close") : t("cancel")}
          </Button>
          <Button
            type="submit"
            variant={
              !!statusChangeInProgress ||
              !!loadingAssets ||
              !!loadingAssetsInProgress
                ? "disabled"
                : "orange"
            }
            size="s"
            onClick={handleToggleActivation}
            disabled={
              !!statusChangeInProgress ||
              !!loadingAssets ||
              !!loadingAssetsInProgress
            }
          >
            {t("change activation")}
          </Button>
        </div>
      }
    >
      {statusChangeInProgress && (
        <>
          <div className={styles.isLoading}>
            <ActivityIndicator />
          </div>
          <div className={styles.info}>
            {t(
              "you can close the window and activation status changing will continue in the background"
            )}
            .
          </div>
          <div className={styles.info2}>
            {t(
              "If you navigate away from Assets tab and then back to it before the process is completed, the page needs to be refreshed manually after the activation status changes are completed for asset statuses to be displayed correctly",
              "asIs"
            )}
            .
          </div>
        </>
      )}
      {!statusChangeInProgress && (
        <div className={styles.delete_txt}>
          {t(`are you sure you want to change the activation of the asset`)}
          <div className={styles.spacing_txt}>{asset?.name} ?</div>
        </div>
      )}
    </Modal>
  );
};

export default ToggleActivateModal;
