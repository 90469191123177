import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import Icon from "../Icon";
import * as Yup from "yup";
import {
  Checkbox,
  FieldError,
  FieldInput,
  FieldLabel,
  FieldSelect,
  Form,
  FormControl,
  OptionsBuilder,
  BooleanBuilder,
} from "../Input";
import NumericalBuilder from "../Input/NumericBuilder";
import InputTagForm from "../InputTag";
import styles from "./FieldBuilder.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const FieldBuilder = ({
  property,
  onSubmit,
  hideTags,
  propertyNames = [],
  hideUpdatable,
  assetView,
  procedures,
  setIsPropertyEdited,
  setShowPropertyEditedWarning,
  formEdit,
  formBuilder,
}) => {
  const { t } = useContext(I18nContext);
  const unitsList = useSelector((state) => state.units.items);

  const initialValuesProperty = {
    name: "",
    tags: [],
    procedureIds: [],
    propertyType: "",
    isMandatory: false,
    isUpdatable: true,
    lowerBound: "",
    upperBound: "",
    unit: "",
    selected: "",
    id: undefined,
  };

  const normalOptions = [
    // { value: "composite", label: t("composite") },
    { value: "boolean", label: t("boolean") },
    { value: "text", label: t("description") },
    { value: "date", label: t("date") },
    { value: "selection-single", label: t("single choice") },
    { value: "selection-multi", label: t("multiple choice") },
    { value: "selection-dropdown", label: t("dropdown") },
    { value: "numeric", label: t("numerical") },
    { value: "numeric-range", label: t("range") },
  ];

  const assetOptions = [
    { value: "boolean", label: t("boolean") },
    { value: "text", label: t("description") },
    { value: "date", label: t("date") },
    { value: "selection-single", label: t("single choice") },
    { value: "selection-multi", label: t("multiple choice") },
    { value: "selection-dropdown", label: t("dropdown") },
    { value: "numeric", label: t("numerical") },
    { value: "numeric-range", label: t("range") },
  ];

  const bluetoothFieldTypeOptions = unitsList
    .filter((unit) => unit.name.includes("bluetooth device"))
    .map((unit) => {
      return {
        value: unit.id,
        label: `${t(unit.name)} (${unit.range.lowerBound}-${
          unit.range.upperBound
        })`,
        bgColor: "#d8e6ff",
      };
    });

  const getPropertyFieldType = (property) => {
    if (property.propertyType === "selection") {
      if (property.dropdown) {
        return "selection-dropdown";
      }
      if (property.multiselect) {
        return "selection-multi";
      } else {
        return "selection-single";
      }
    } else if (property.propertyType === "numeric") {
      if (property.hasRange) {
        return "numeric-range";
      } else {
        return "numeric";
      }
    } else {
      return property.propertyType;
    }
  };

  const [initialState, setInitialValues] = useState(initialValuesProperty);
  const [showTag, setShowTag] = useState(false);
  const [procedureIds, setSelectedProcedures] = useState(
    property ? procedures : []
  );
  const [tags, setTags] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState("");

  Yup.addMethod(Yup.array, "uniqueProperty", function (propertyPath, message) {
    return this.test("unique", "", function (list) {
      const errors = [];

      list.forEach((item, index) => {
        const propertyValue = _.get(item, propertyPath);

        if (
          propertyValue &&
          _.filter(list, [propertyPath, propertyValue]).length > 1
        ) {
          errors.push(
            this.createError({
              path: `${this.path}[${index}].${propertyPath}`,
              message,
            })
          );
        }
      });

      if (!_.isEmpty(errors)) {
        throw new Yup.ValidationError(errors);
      }

      return true;
    });
  });

  const validationSchemaProperty = Yup.object({
    name: property
      ? Yup.mixed().required(t("please enter the name of the property"))
      : Yup.mixed()
          .notOneOf(propertyNames, t("name must be unique"))
          .required(t("please enter the name of the property")),
    propertyType: Yup.string().required(t("please select field type")),
    isMandatory: Yup.bool(),
    lowerBound: Yup.number().when(
      ["unit", "propertyType"],
      (unit, propertyType, schema) => {
        return schema.test({
          test: (lowerBound) => {
            return (
              (!!unit &&
                !bluetoothFieldTypeOptions
                  .map((option) => option.value)
                  .includes(unit) &&
                unitsList.find((x) => x.id === unit)?.range?.lowerBound <=
                  lowerBound &&
                unitsList.find((x) => x.id === unit)?.range?.upperBound >=
                  lowerBound) ||
              (!!unit &&
                bluetoothFieldTypeOptions
                  .map((option) => option.value)
                  .includes(unit) &&
                lowerBound >= 0 &&
                lowerBound <= (unit === "DURATION" ? 300 : 100)) ||
              !unit ||
              propertyType !== "numeric-range"
            );
          },
          message:
            t("value is out of unit range") +
            `${
              unit &&
              !bluetoothFieldTypeOptions
                .map((option) => option.value)
                .includes(unit)
                ? " (" +
                  unitsList.find((x) => x.id === unit)?.range?.lowerBound +
                  "-" +
                  unitsList.find((x) => x.id === unit)?.range?.upperBound +
                  ")"
                : bluetoothFieldTypeOptions
                    .map((option) => option.value)
                    .includes(unit) && unit !== "DURATION"
                ? " (0-100)"
                : unit === "DURATION"
                ? " (0-300)"
                : ""
            }`,
        });
      }
    ),
    upperBound: Yup.number()
      .when(["unit", "propertyType"], (unit, propertyType, schema) => {
        return schema.test({
          test: (upperBound) => {
            return (
              (!!unit &&
                !bluetoothFieldTypeOptions
                  .map((option) => option.value)
                  .includes(unit) &&
                unitsList.find((x) => x.id === unit)?.range?.lowerBound <=
                  upperBound &&
                unitsList.find((x) => x.id === unit)?.range?.upperBound >=
                  upperBound) ||
              (!!unit &&
                bluetoothFieldTypeOptions
                  .map((option) => option.value)
                  .includes(unit) &&
                upperBound >= 0 &&
                upperBound <= (unit === "DURATION" ? 300 : 100)) ||
              !unit ||
              propertyType !== "numeric-range"
            );
          },
          message:
            t("value is out of unit range") +
            `${
              unit &&
              !bluetoothFieldTypeOptions
                .map((option) => option.value)
                .includes(unit)
                ? " (" +
                  unitsList.find((x) => x.id === unit)?.range?.lowerBound +
                  "-" +
                  unitsList.find((x) => x.id === unit)?.range?.upperBound +
                  ")"
                : bluetoothFieldTypeOptions
                    .map((option) => option.value)
                    .includes(unit) && unit !== "DURATION"
                ? " (0-100)"
                : unit === "DURATION"
                ? " (0-300)"
                : ""
            }`,
        });
      })
      .when(
        ["lowerBound", "propertyType"],
        (lowerBound, propertyType, schema) => {
          return schema.test({
            test: (upperBound) =>
              upperBound > lowerBound || propertyType !== "numeric-range",
            message: t("max value must be higher than min value"),
          });
        }
      ),
    unit: Yup.string().when("propertyType", (propertyType, schema) => {
      return schema.test({
        test: (unit) =>
          !!unit ||
          (propertyType !== "numeric-range" && propertyType !== "numeric"),
        message: t("please select one of the options"),
      });
    }),
    trueLabel: Yup.string().when("propertyType", (propertyType, schema) => {
      return schema.test({
        test: (trueLabel) => !!trueLabel || propertyType !== "boolean",
        message: t("this field is required, please provide a value."),
      });
    }),
    falseLabel: Yup.string().when("propertyType", (propertyType, schema) => {
      return schema.test({
        test: (falseLabel) => !!falseLabel || propertyType !== "boolean",
        message: t("this field is required, please provide a value."),
      });
    }),
    trueColor: Yup.string().when("propertyType", (propertyType, schema) => {
      return schema.test({
        test: (trueColor) => !!trueColor || propertyType !== "boolean",
        message: t("this field is required, please provide a value."),
      });
    }),
    falseColor: Yup.string().when("propertyType", (propertyType, schema) => {
      return schema.test({
        test: (falseColor) => !!falseColor || propertyType !== "boolean",
        message: t("this field is required, please provide a value."),
      });
    }),
    isUpdatable: Yup.bool(),
  });

  const handleSubmit = (values, formik) => {
    if (setShowPropertyEditedWarning) {
      setShowPropertyEditedWarning(false);
    }

    onSubmit(
      {
        property: values,
        tags,
        procedureIds,
      },
      formik
    );
    setTags([]);
    setSelectedProcedures([]);
  };

  useEffect(() => {
    if (property) {
      setSelectedPropertyType(property.propertyType);
      setInitialValues({
        name: formEdit ? property.name.split("__").pop() : property.name,
        tags: [],
        procedureIds: procedures,
        propertyType: getPropertyFieldType(property),
        isMandatory: property.flags.isMandatory,
        isUpdatable: property.flags.isUpdatable,
        upperBound: property.range?.upperBound,
        lowerBound: property.range?.lowerBound,
        unit: property.unit?.id,
        bluetoothMeasurementType: property?.bluetoothMeasurementType,
        selected: property.value,
        id: property?.id,
        trueLabel: property?.trueLabel,
        falseLabel: property?.falseLabel,
        trueColor: property?.trueColor,
        falseColor: property?.falseColor,
      });
    }
  }, [property]);

  return (
    <>
      <Form
        id={property ? "edit-proptotype-property" : "add-proptotype-property"}
        onSubmit={handleSubmit}
        initialValues={initialState}
        validationSchema={validationSchemaProperty}
        enableReinitialize
        setIsPropertyEdited={setIsPropertyEdited}
      >
        {({ values, errors }) => {
          return (
            <>
              <div className={styles.start_row}>
                <FormControl>
                  <FieldLabel>
                    {t("property name")}
                    <span className={styles.red}> *</span>
                  </FieldLabel>
                  <FieldInput
                    name="name"
                    type="text"
                    placeholder={t("property name")}
                    size="m"
                  />
                  <FieldError name="name" />
                </FormControl>
                <FormControl className={styles.dropdown}>
                  <FieldLabel>
                    {t("field type")}
                    <span className={styles.red}> *</span>
                  </FieldLabel>
                  <FieldSelect
                    name="propertyType"
                    size="s"
                    options={assetView ? assetOptions : normalOptions}
                    disabled={property}
                    placeholder={t("field type")}
                    onChange={(value) => {
                      if (value.startsWith("selection")) {
                        setSelectedPropertyType("selection");
                        return;
                      }
                      if (value.startsWith("numeric")) {
                        setSelectedPropertyType("numeric");
                        return;
                      }
                      setSelectedPropertyType(value);
                    }}
                  />
                  <FieldError name="propertyType" />
                </FormControl>
                <span className={styles.checkbox_required}>
                  <Checkbox
                    name="isMandatory"
                    label={t("required field", "title")}
                  />
                </span>
                {!hideUpdatable && !formBuilder && (
                  <span className={styles.checkbox_required}>
                    <Checkbox
                      name="isUpdatable"
                      label={t("updatable field", "title")}
                    />
                  </span>
                )}
                {!property && (
                  <button className={styles.add_btn} type="submit">
                    {!!formBuilder ? (
                      t("add property to form")
                    ) : (
                      <Icon className={styles.add_icon} name="add-form-modal" />
                    )}
                  </button>
                )}
              </div>
              {values.propertyType === "boolean" && (
                <BooleanBuilder key="boolean" property={property} />
              )}
              {values.propertyType === "numeric" && (
                <NumericalBuilder
                  key="numeric"
                  range={false}
                  property={property}
                  formProperty={formBuilder || formEdit}
                  bluetoothFieldTypeOptions={bluetoothFieldTypeOptions}
                />
              )}
              {values.propertyType === "numeric-range" && (
                <NumericalBuilder
                  key="numeric-range"
                  range={true}
                  property={property}
                  formProperty={formBuilder || formEdit}
                  bluetoothFieldTypeOptions={bluetoothFieldTypeOptions}
                />
              )}
              {values.propertyType === "selection-single" && (
                <OptionsBuilder
                  key="selection-single"
                  name="options"
                  multiselect={false}
                  // showSelection
                  property={property}
                />
              )}
              {values.propertyType === "selection-multi" && (
                <OptionsBuilder
                  key="selection-multi"
                  name="options"
                  multiselect={true}
                  // showSelection
                  property={property}
                />
              )}
              {values.propertyType === "selection-dropdown" && (
                <OptionsBuilder
                  key="selection-dropdown"
                  name="options"
                  dropdown={true}
                  multiselect={false}
                  property={property}
                />
              )}

              <div className={styles.column}>
                <div className={styles.add_section}>
                  {!hideTags && !showTag && (
                    <span
                      className={styles.cursor}
                      onClick={() => setShowTag(true)}
                    >
                      {`+ ${t("add tags")}`}
                    </span>
                  )}
                </div>
              </div>

              {showTag && (
                <div>
                  <FieldLabel>{t("tags")}</FieldLabel>
                  <InputTagForm
                    name="tags"
                    onDelete={() => setShowTag(false)}
                    onChange={setTags}
                  />
                </div>
              )}
            </>
          );
        }}
      </Form>
    </>
  );
};

export default FieldBuilder;
